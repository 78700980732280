<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  size="12"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                  @change="handlePlateNumber"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.arrears')">
                <el-input
                  type="text"
                  v-model="formInline.debtStartMoney"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mglr8">至</span>
                <el-input
                  type="text"
                  v-model="formInline.debtEndMoney"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mgl8">元</span>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Accessibility')">
                <el-select v-model.trim="formInline.status" size="15">
                  <el-option
                    v-for="(item, index) in selectList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="会员电话">
                <el-select v-model.trim="formInline.haveMemberPhone" size="15">
                  <el-option
                    v-for="(item, index) in haveMemberPhoneList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_arrears_recovery')">
                <el-input
                  type="text"
                  v-model="formInline.mobileRecoverStartNum"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mglr8">至</span>
                <el-input
                  type="text"
                  v-model="formInline.mobileRecoverEndNum"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mgl8">次</span>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Autonomous_parking')">
                <el-select v-model.trim="formInline.selfPhoneStatus" size="15">
                  <el-option
                    v-for="(item, index) in selectList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.security_interface_telephone')">
                <el-select v-model.trim="formInline.policePhoneStatus" size="15">
                  <el-option
                    v-for="(item, index) in selectList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">清空</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                @exportFile="handleExportFile"
                style="display: inline-block"
                :exportData="exportData"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            v-for="item in tableCols"
            :key="item.prop"
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'phone'">
                <table-phone
                  :row="scope.row"
                  :isSuceess="scope.row[item.showParams.statusKey] == 1"
                  :phone="scope.row[item.prop]"
                  :tips="scope.row[item.showParams.textKey]"
                />
              </div>
              <div v-else>
                <p>{{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">欠费详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <arrearage-details :detailShow="detailShow" :rowData="rowData" @close="detailShow = false" />
  </div>
</template>
<script>
import exportFile from "@/components/exportFile/exportFile.vue";
import arrearageDetails from "@/components/arrearage/arrearageDetails.vue";
import tablePhone from "@/components/tablePhone/tablePhone.vue";
import { compareSize } from "@/common/js/utils.js";
export default {
  name: "arrearageVehicle",
  data() {
    return {
      formInline: {
        plateNumber: "",
        carId: "",
        debtStartMoney: "",
        debtEndMoney: "",
        status: "",
        haveMemberPhone: "",
        mobileRecoverStartNum: "",
        mobileRecoverEndNum: "",
        selfPhoneStatus: "",
        policePhoneStatus: "",
      },
      rule: {},
      selectList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "是",
          value: "1",
        },
        {
          label: "否",
          value: "0",
        },
      ],
      haveMemberPhoneList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "有",
          value: "1",
        },
        {
          label: "无",
          value: "0",
        },
      ],
      loading: false,
      tableData: [],
      total: 0,
      pageSize: 15,
      pageNum: 1,
      exportData: {},
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "carArea",
          label: "车辆归属地",
          width: "",
        },
        {
          prop: "debtMoney",
          label: "欠费总金额",
          width: "",
          formatter: (row, column) => {
            if (row.debtMoney) {
              if (row.debtMoney >= 0) {
                return Number(row.debtMoney / 100).toFixed(2) + "元";
              } else {
                return row.debtMoney + "元";
              }
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "recoverMoney",
          label: "追缴金额",
          width: "",
          formatter: (row, column) => {
            if (row.recoverMoney) {
              if (row.recoverMoney >= 0) {
                return Number(row.recoverMoney / 100).toFixed(2) + "元";
              } else {
                return row.recoverMoney + "元";
              }
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "debtOrderNum",
          label: "欠费总订单数",
          width: "",
        },
        {
          prop: "recoverCount",
          label: "追缴单数",
          width: "",
        },
        {
          prop: "mobileRecoverNum",
          label: "电话追缴次数",
          width: "",
        },
        {
          prop: "memberPhone",
          label: "会员电话",
          type: "phone",
          width: "150",
          showParams: {
            statusKey: "memberPhoneStatus",
            textKey: "memberPhoneInvalidTypeDesc",
          },
        },
        {
          prop: "policePhone",
          label: "公安接口电话",
          type: "phone",
          width: "150",
          showParams: {
            statusKey: "policePhoneStatus",
            textKey: "policePhoneInvalidTypeDesc",
          },
        },
        {
          prop: "thirdPhone",
          label: "三方导入电话",
          type: "phone",
          width: "150",
          showParams: {
            statusKey: "thirdPhoneStatus",
            textKey: "thirdPhoneInvalidTypeDesc",
          },
        },
        {
          prop: "selfPhone",
          label: "近日自主停车",
          type: "phone",
          width: "150",
          showParams: {
            statusKey: "selfPhoneStatus",
            textKey: "selfPhoneInvalidTypeDesc",
          },
        },
        {
          prop: "statusDesc",
          label: "可触达性",
          width: "",
          formatter: (row, column) => {
            if (row.statusDesc) {
              return row.statusDesc;
            } else {
              return "否";
            }
          },
        },
      ],
      detailShow: false,
      rowData: {},
    };
  },
  created() {
    this.handleExportFile();
    this.searchData();
  },
  methods: {
    handlePlateNumber(value) {
      if (!value) {
        this.formInline.carId = "";
        this.pageNum = 1;
        this.searchData();
      }
    },
    handleDetail(row) {
      this.rowData = row;
      this.detailShow = true;
    },
    searchData() {
      if (!this.formInline.carId) {
        this.formInline.plateNumber = "";
      }
      const { debtStartMoney, debtEndMoney, mobileRecoverStartNum, mobileRecoverEndNum } =
        this.formInline;
      if (!compareSize(debtStartMoney, debtEndMoney, "欠费金额")) return;
      if (!compareSize(mobileRecoverStartNum, mobileRecoverEndNum, "电话追缴次数")) return;
      this.loading = true;
      let opt = {
        method: "get",
        url: "/acb/2.0/debtCar/list",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
          debtStartMoney:
            this.formInline.debtStartMoney !== "" ? this.formInline.debtStartMoney * 100 : "",
          debtEndMoney:
            this.formInline.debtEndMoney !== "" ? this.formInline.debtEndMoney * 100 : "",
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    resetForm() {
      this.formInline = {
        plateNumber: "",
        carId: "",
        debtStartMoney: "",
        debtEndMoney: "",
        status: "",
        haveMemberPhone: "",
        mobileRecoverStartNum: "",
        mobileRecoverEndNum: "",
        selfPhoneStatus: "",
        policePhoneStatus: "",
      };
    },
    handleExportFile() {
      console.log("this.formInline", this.formInline);
      let data = JSON.parse(JSON.stringify(this.formInline));
      data.debtStartMoney = data.debtStartMoney * 100;
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/debtCar/export",
        methods: "post",
        data: {
          name: "欠费车辆",
          ...data,
        },
      };
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
  },
  components: {
    exportFile,
    arrearageDetails,
    tablePhone,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">

.mglr8 {
    margin: 0 8px;
  }
  .mgl8 {
    margin-left: 8px;
  }
// /deep/ {
//   .phone-success {
//     display: inline-block;
//     padding: 4px 10px;
//     background-color: #70b603;
//     color: #fff;
//     border-radius: 4px;
//   }
//   .phone-error {
//     display: inline-block;
//     padding: 4px 10px;
//     background-color: #aaaaaa;
//     color: #fff;
//     border-radius: 4px;
//   }
//   .hover-dom {
//     cursor: pointer;
//   }
// }
</style>
